import React, {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {Provider} from 'react-redux'
import store from '../vendors/redux/store'
import {AuthPage1} from '../modules/auth/AuthPage1'

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  // eslint-disable-next-line no-restricted-globals
  const pathName: string = location.search
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Provider store={store}>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            {currentUser ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/dashboard' />} />
              </>
            ) : pathName !== '' ? (
              <>
                <Route path='confirmEmail' element={<AuthPage1 />} />
                <Route path='confirmEmail' element={<Navigate to='/confirmEmail' />} />
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}
          </Route>
        </Routes>
      </Provider>
    </BrowserRouter>
  )
}

export {AppRoutes}
