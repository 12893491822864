export default class Environment {
  private static env = process.env.REACT_APP_ENV;

  private static url = process.env.REACT_APP_API_URL;

  public static getEnv() {
    return this.env;
  }

  public static getUrl() {
    return this.url;
  }
}