/* eslint-disable no-restricted-globals */
import React, {useEffect, useState} from 'react'
//import * as Yup from 'yup'
///import clsx from 'clsx'
import {useFormik} from 'formik'
import { requestConfirmEmail } from '../core/_requests'

const initialValues = {}

const ConfirmEmail: React.FC = () => {
  const [loading, setLoading] = useState(false)

  const searchParams = new URLSearchParams(location.search)

  const [code] = useState<any | string>(searchParams.get('code'))
  const [userId] = useState<any | string>(searchParams.get('userId'))
  const [confirm] = useState<any>(searchParams.get('confirm'))

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: () => {
      setLoading(true)
      console.log('onSubmit', formik.isSubmitting)
      try {
        setTimeout(() => {
          requestConfirmEmail(code,userId)
          .then(({data: {result}}) => {            
            setLoading(false)
          })
          .catch(() => {            
            setLoading(false)
          })          
        }, 1000)
      } catch (error) {
        setLoading(false)
      }
    },
  })

  const validar = () => {
    if (
      code !== null &&
      userId !== null &&
      confirm !== null &&
      code !== '' &&
      userId !== '' &&
      confirm !== '' &&
      code !== undefined &&
      userId !== undefined &&
      confirm !== undefined
    ) {
      console.log('code', code)
      console.log('userId', userId)
      console.log('confirm', confirm)
      formik.submitForm()
      return true
    } else {
      console.log('Erro')
      return false
    }
  }

  useEffect(() => {
    if (!formik.isSubmitting) {
      validar()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_confirm_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark fw-bolder mb-3'>Confirmação de e-mail</h1>
          <div className='text-gray-500 fw-semibold fs-6'>
            {/* {!loading && <span className='indicator-label'>Salvar</span>} */}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Por favor, espere você será rediredionado...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </div>
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-8'></div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'></div>
        {/* end::Form group */}
      </form>
    </>
  )
}

export {ConfirmEmail}
