import {Route, Routes} from 'react-router-dom'
import {AuthLayout} from './AuthLayout'
import {ConfirmEmail} from './components/ConfirmEmail'

const AuthPage1 = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='confirmEmail' element={<ConfirmEmail />} />
      <Route index element={<ConfirmEmail />} />
    </Route>
  </Routes>
)

export {AuthPage1}
